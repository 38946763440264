import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="button-clicker"
export default class extends Controller {

  static targets = ['target']

  targetClick() {
    this.targetTarget.click();
  }
}
